$(document).ready(function(){

    if($('body').hasClass('is-product')) {
        // Tabs materiali
        $('.mat-tabs__item').on('click',function () {
            var dataTab = $(this).data('toggle');

            $('.mat-tabs__item').removeClass('active');
            $(this).addClass('active');
            
            $('.mat-variant').removeClass('active');
            $('.mat-cont-tabs #' + dataTab ).addClass('active');
        });

        // View all variants
        $('.btn--variant').on('click',function() {
            $(this).toggleClass('enabled');
            if( $(this).closest('.mat-variant').length > 0 ){
                var variantList = $(this).closest('.mat-variant');
            }else{
                var variantList = $(this).closest('.product-variant');
            }

            variantList.toggleClass('is-visible');
        });

        //Modale
        var modal = $('#modalVariant');
        $('[data-toggle=modal]').on('click',function(e) {
            e.preventDefault();
            var image =  $(this).attr('href');
            var type = $(this).data('type');
            var text = $(this).closest('.mat-variant__item').find('span').text();

            modal.find('.modal__title').text(text);
            modal.find('.modal__subtitle').text(type);
            modal.find('.modal__main img').attr('src',image);
            modal.modal();
        });

        modal.on('hidden.bs.modal', function (e) {
            modal.find('.modal__title').text('');
            modal.find('.modal__subtitle').text('');
            modal.find('.modal__main img').attr('src','');
        });
    }

    // Reszie
    resize();
    $(window).resize(function() {
        resize();
    });
    function resize() {
        if( $(window).width() > 800 ){
            controlGridGallery();
        }

        // Rendo visibile la sezione
        $('.grid-gallery').addClass('is-visible');
    }

    // Control grid gallery
    function controlGridGallery() {
        setTimeout(function(){
            var gridCol = $('.grid-gallery__col').length;
            var gridItem = $('.grid-gallery__item').length;
            var verticalCount = $('.grid-gallery .vertical').length;
            var widthWide = $('.grid-gallery__col:last-child').find('.grid-gallery__item:last-child').width();
            var widthVertical = $('.grid-gallery .vertical img').width();
            var margin = '';

            // Only one
            if( gridCol == 1 && gridItem == 1 ) {
                $('.grid-gallery__col').addClass('centered');
            }else{
                // controllo            
                if( verticalCount == 1 ){
                    margin = widthWide - widthVertical + 10;
                    $('.grid-gallery .vertical').css('margin-right',margin + 'px');
                    //$('.grid-gallery .down').addClass('centered')
                } else if( verticalCount == 0 ) {
                    margin = widthWide + 30;
                    $('.grid-gallery .quadro').css('margin-right',margin + 'px');                    
                }
            }                           
        }, 800);
    }
});