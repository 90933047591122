$(document).ready(function(){

    //Formbuilder Ajax
    var $ajaxForm = $('form.formbuilder.ajax-form');

    if( $ajaxForm.length > 0 ){
        $ajaxForm.formBuilderAjaxManager();
        $ajaxForm.formBuilderTracker({
            onBeforeSubmitDataToProvider: function(data, formName, $form) {
                // add some special value to data
                // warning: in some cases, no data will be submitted (gtag, ga)
                return data;
            },
            provider: 'google', // choose between "google" or "matomo"
            trackDropDownSelection: true,
            trackCheckboxSelection: true,
            trackRadioSelection: true,
            trackHiddenInputs: true,
            invalidFieldNames: ['_token', 'formCl']
        });

        $ajaxForm
            .on('formbuilder.success', function (ev, message, redirect, $form) {
                if (redirect) {
                    $(window).attr('location', redirect);
                } else {
                    $( "div" ).remove( ".message-success" );
                    $ajaxForm.prepend('<div class="message message-success">'+message[0].message+'</div>');
                    $('body,html').animate({scrollTop:0},600);
                }
            }).on('formbuilder.error', function (ev, messages, $form) {
            console.log(messages);
        }).on('formbuilder.error-form', function (ev, messages, $form) {
            $(".error-message").html("<p>"+messages+"</p>");
            console.error('error-form', messages);
        }).on('formbuilder.error-field', function(ev, data, $form) {
            console.log(data.field, data.messages);
            $(".error-message").html("<p>"+data.messages+"</p>");
        }).on('formbuilder.fatal', function (ev, response, $form) {
            console.error('fatal', response.error, response.trace);
        });
    }   

    // Find data-class attribute
    $('.site-form [data-class]').each(function(){
        var dataClass = $(this).data('class');
        
        $(this).closest('.form-group').addClass(dataClass);
    });
});
