$(function(){
    // Slick slider
    $('.slick-slider').each(function(){
        var el = $(this);
        var options = {
            lazyLoad: el.data('lazy-load'),
            fade: el.data('fade'),
            variableWidth: el.data('variable-width'),
            autoplay: el.data('autoplay'),
            slidesToShow: el.data('slides-to-show'),
            slidesToScroll: 1,
            dots: el.data('dots'),
            arrows: el.data('arrows'),
            asNavFor: el.data('as-nav-for'),
            centerMode: el.data('center-mode'),
            infinite: true
        };
        el.slick(options);
    });

    // Slide columns
    $('.slide-columns').each(function(){
        var el = $(this);
        var options = {
            lazyLoad: true,
            slidesToShow: el.data('slides'),
            slidesToScroll: 1,
            variableWidth: false,
            dots: false,
            arrows: true,
            infinite: true
        };
        el.slick(options);
    });

    // Lightgallery
    $('.light-box').lightGallery();

    // Carousel
    $('.brick-carousel .owl-carousel').owlCarousel({
        items: 1,
        rewindSpeed: 500,
        nav: false,
        dots: true,
        loop: true,
        center: true,
    });

    // Carousel timeline
    $(".brick-carousel-timeline .owl-carousel")
        .owlCarousel({
			items: 1,
			dots: true,
		})
		.find('.owl-item')
		.each(function(i){
			var attr = $(this).children().attr('data-year');
			var element = $('<span>'+attr+'</span>');
			$('.brick-carousel-timeline .owl-carousel .owl-dot').eq(i).append(element);
		});

    // Tabs brick
    $('.single-tab').on('click',function () {
        var dataTab = $(this).data('toggle');

        $('.single-tab').removeClass('active');
        $(this).addClass('active');

        $('.spot-tabs').removeClass('active');
        $('.spot-tabs-cont #' + dataTab ).addClass('active');
    });

    //Toolbox - GMaps
    if( $('.toolbox-googlemap').length > 0 ){
        $('.toolbox-googlemap').toolboxGoogleMaps({
            centerMapOnResize: true,
            theme: 'bootstrap4'
        });
    }

    //Toolbox - Video
    if( $('.toolbox-video').length > 0 ){
        $('.toolbox-video').toolboxVideo({
            theme: 'bootstrap4',
            videoIdExtractor: {
               custom: function (videoId) {
                   //console.log(videoId);
                   return videoId;
               }
           },
           resources: {
               youtube: 'https://www.youtube.com/iframe_api',
               vimeo: 'https://player.vimeo.com/api/player.js',
           },
           apiParameter: {
               youtube: {
                   rel: 0 //disable related videos
               },
               vimeo: {}
           }
         });
    }

    // Toolbox video lightbox
    $(".video-inner .poster-overlay.lightbox").on("click", function(e){
        // prevent default behavior for a-tags, button tags, etc.
        e.preventDefault();
        // Grab the video ID from the element clicked
        var id = $(this).closest('.video-inner').find('.player').data('video-uri');
        // Autoplay when the modal appears
        // Note: this is intetnionally disabled on most mobile devices
        // If critical on mobile, then some alternate method is needed
        var autoplay = '?autoplay=1';
        // Don't show the 'Related Videos' view when the video ends
        var related_no = '&rel=0';
        // String the ID and param variables together
        var src = '//www.youtube.com/embed/' + id + autoplay + related_no;
        // Pass the YouTube video ID into the iframe template...
        // Set the source on the iframe to match the video ID
        $("#youtube").attr('src', src);
        // Add class to the body to visually reveal the modal
        $("body").addClass("show-video-modal noscroll");
    });
    // Close and Reset the Video Modal
    function close_video_modal() {
        event.preventDefault();
        // re-hide the video modal
        $("body").removeClass("show-video-modal noscroll");
        // reset the source attribute for the iframe template, kills the video
        $("#youtube").attr('src', '');
    }
    // if the 'close' button/element, or the overlay are clicked
    $('body').on('click', '.close-video-modal, .video-modal .overlay', function(event) {
        // call the close and reset function
        close_video_modal();
    });

    // Store Locator quick search
    var $storesQuickSearchForm = $('.form-stores-quick-search');
    if(!$('body').hasClass('is-editmode') && $storesQuickSearchForm.length > 0) {

        var $storesQuickSearchInput = $storesQuickSearchForm.find('input[type="text"]');

        $storesQuickSearchForm.submit(function (e) {
            e.preventDefault();
            const params = new URLSearchParams({ q:$storesQuickSearchInput.val() });
            window.location.href = $storesQuickSearchForm.attr('action')+'?'+params.toString();
        });

        var autocomplete = new google.maps.places.Autocomplete($storesQuickSearchInput[0]);
        autocomplete.addListener('place_changed', function () {
            var placeAutocomplete = autocomplete.getPlace();
            if (placeAutocomplete && placeAutocomplete.place_id) {
                const params = new URLSearchParams({ q:$storesQuickSearchInput.val(), id: placeAutocomplete.place_id});
                window.location.href = $storesQuickSearchForm.attr('action')+'?'+params.toString();
            }
        });
    }
});
