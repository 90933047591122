
// Append before site-footer
$(document).ready(function() {
    $('.nav-foot-sticky').appendTo('body');
});

// Scroll event func
$(window).on('scroll',function() {
    var value = $(window).height() + $(window).scrollTop();
    var scroll  = $(window).scrollTop();
    var $navFoot = $('.nav-foot-sticky');
    var offsetFooter = Math.floor($('.site-footer').offset().top);

    if( scroll > 300 ){
        $navFoot.addClass('is-visible')
    }else{
        $navFoot.removeClass('is-visible')
    }

    // Scrollo fino al footer e scateno un'azione
    // Rimuovo classe al footer
    if( value >= offsetFooter ) {
        $navFoot.removeClass('is-visible');
        $('.site-footer').addClass('add-index')
        //$navFoot.css('bottom', Math.abs(offsetFooter - value) + 'px');
    }else{
        //$navFoot.addClass('is-visible');
        $('.site-footer').removeClass('add-index')
        //$navFoot.css('bottom','0px');
    }
});