$(document).ready(function(){
    if($('body').hasClass('is-finiture')) {

        var modal = $('#modalVariant');

        $('[data-toggle=modal]').on('click',function(e) {
            e.preventDefault();
            var image =  $(this).attr('href');
            var type = $(this).data('type');
            var text = $(this).data('text');

            modal.find('.modal__title').text(text);
            modal.find('.modal__subtitle').text(type);
            modal.find('.modal__main img').attr('src',image);
            modal.modal();
        });

        modal.on('hidden.bs.modal', function (e) {
            modal.find('.modal__title').text('');
            modal.find('.modal__subtitle').text('');
            modal.find('.modal__main img').attr('src','');
        });
    }
});