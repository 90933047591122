$(document).ready(function(){

    var ie10 = 'behavior' in document.documentElement.style && '-ms-user-select' in document.documentElement.style;
    var ie11 = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;

    // Control explorer
    if( ie10 ){
        $('html').addClass('ie-10');
    }
    if( ie11 ){
        $('html').addClass('ie-11');
    }
    
    // Owl carousel with imagesloaded / centered with loop
    var carousel = $('.product-slider-cont .owl-carousel').owlCarousel({
        margin: 10,
        nav: false,
        dots: true,
        loop: false,
        autoWidth: true,
        item: 1,
        loop: false,
        rewind: true,
        center: true,
        lazyLoad: false,
        slideSpeed: 500,
        paginationSpeed: 500,
        slideTransition: 'ease',
        onDrag: function(){
            $('[rel=tooltip]').tooltip('hide');
        }
    });
    carousel.imagesLoaded( function() {
        carousel.trigger( 'refresh.owl.carousel' );
    });
    

    // Control tooltip on hover figure container
    if( $('.img-tooltip__cont').length != 0 ){
        $('.tooltip.show').mouseenter(function() {
            $('body').addClass('view-tooltip');
        });
        $('.tooltip.show').mouseleave(function() {
            $('body').removeClass('view-tooltip');
        });
    }


    $('.owl-dots button').on('click',function(){
        $('[rel=tooltip]').tooltip('hide');
    });

    // Toggle list product hide
    $('.js-list .toggle-list').on('click',function() {
        $(this).toggleClass('enabled');
        $(this).closest('.js-list').find('.no-view').toggleClass('hide show');
    });

    // Zoom texture
    if( $('.add-zoom').length > 0 ){
        $('.add-zoom').zoom({
            on:'grab',
            url: $('.add-zoom').attr('data-zoom')
        });
    }

    // Toggle lang list
    /*$('.current-lang').on('click',function(e) {
        $('.lang-list').toggleClass('is-visible');
    });*/

    $(document).on('click',function(e) {
        if(!$('.current-lang').is(e.target) && $('.current-lang').has(e.target).length === 0){
            $('.lang-list').removeClass('is-visible');
            $('.current-lang').removeClass('active');
        }else{
            $('.current-lang').toggleClass('active');
            $('.lang-list').toggleClass('is-visible');
        }
    });

    // Input checked
    $('.filters').find('input[type=checkbox]').on('click touchstart',function () {
        $(this).closest('label').toggleClass('is-checked');
    })


    // Tooltip hover / al click
    $('[rel=tooltip]').tooltip({
        animated: 'fade',
        placement: 'top',
        trigger: 'click',
        html: true
    });

    // Tooltip info
    $('[rel=tooltip-info]').tooltip({
        animated: 'fade',
        placement: 'top',
        trigger: 'hover',
        html: true
    });

    // Smooth scroll
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .on('click', function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function () {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                    } else {
                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                    };
                });
            }
        }
    });

    // Light gallery
    if( $('.owl-carousel--lightbox').length > 0 ){
        $('.owl-carousel--lightbox').lightGallery({
            thumbnail: false,
            selector: '.grid-gallery__item'
        });
    }

    // Resize
    resize();
    $(window).on('resize',function(){
        resize();
    });

    // Control devices and resize brw
    function resize() {
        if( $('html').hasClass('tablet') || $('html').hasClass('mobile') ){
            $('body').addClass('only-touch');
        }else{
            $('body').removeClass('only-touch');
        }
    }

    //form registrazione utente membersbundle/reserved area
    var authorized        = $('#members_user_registration_form_authorized');
    var activationCode    = $('#members_user_registration_form_activationCode');
    var activationCodeRow = $('.js-row-activation-code');
    var formPartialBlock  = $('#register-form-partial-fields');

    if ($(authorized).find("option:selected").val() == '') {
        activationCodeRow.hide();
        formPartialBlock.hide();
    }

    if($(authorized).find("option:selected").val() != 1) {
        activationCodeRow.hide();
    }

    $(authorized).change(function() {

        if ($(this).find("option:selected").val() != '') {
           formPartialBlock.fadeIn(500);
        }

        activationCode.val('');
        if ($(this).find("option:selected").val() == 1) {
            activationCodeRow.fadeIn(500);
        }else {
            activationCodeRow.fadeOut(200);
        }
    });

    var tabs = $("body").find('[data-toggle="tab"]');
    // var tabs = document.querySelectorAll('[data-toggle="tab"]');

    $.each( tabs, function (i, tab){

        var panelId = $(tab).attr('aria-controls');
        var panelContent = $('#'+panelId);
        var liItems = $(panelContent).find('li').length;

        if(liItems === 0){
            $(tab).remove();
            $(panelContent).remove();
        }
    });

    var formPassword = $('.site-form input[type="password"]');
    formPassword.after('<i class="fa fa-fw fa-eye-slash field-icon password-eye js-toggle-password"></i>');

    $(".js-toggle-password").click(function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var inputPassword = $(this).prev();

        if (inputPassword.attr("type") === "password") {
            inputPassword.attr("type", "text");
        } else {
            inputPassword.attr("type", "password");
        }
    });

    var siteForm = $('.site-form');
    var siteFormSelect = siteForm.find('select');

    siteForm.find("select").each(function () {
        changeColorSelect($(this));
    });

    siteFormSelect.change(function(){
        changeColorSelect($(this));
    });

    function changeColorSelect(el) {
        el.val() == '' ? el.css("color", "#c3c3c3") : el.css("color", "#1D1D1B");
    }
});
