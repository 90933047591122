$(document).ready(function(){

    if($('body').hasClass('is-search')) {

        $('.search__form_button').click(function () {
            var urlParams = new URLSearchParams();
            urlParams.append('q', $(this).closest('form.search__form').find('input[name="q"]').val());
            history.replaceState(null, null, '?' + urlParams.toString());
            window.location.reload();
        });
    }
});