$(document).ready(function(){
    // Variables
    var elBody = $('body');
    var megamenuBg = $('.megamenu__bg');
    var navheadItem = $('.nav-head__item.with-menu');
    var navheadItemLink = $('.nav-head__item.with-menu > a');
    var megamenuItem = $('.megamenu__item');
    var megamenuSubitem = $('.megamenu__subitem');
    var productSlider = $('.product-slider');
    var productSliderV = $('.product-slider.with-v');
    var $header = $('.site-header');
    var $sidebar = $('.sidebar-content');

    // Open megamenu
    navheadItemLink.on('click', function() {
        var listItem = $(this).closest('.nav-head__item');
        var megamenu = listItem.find('.megamenu');

        navheadItem.removeClass('is-selected');
        elBody.removeClass('menu-is-visible');
        elBody.removeClass('with-bg-image');
        $('.megamenu').removeClass('current-visible');
        megamenuBg.find('img').attr('src','');

        if( !listItem.hasClass('is-selected') ){
            listItem.addClass('is-selected');
            elBody.addClass('menu-is-visible');
            megamenu.addClass('current-visible');
        }
    });

    // CLose MM
    $('.megamenu').on('click',function (e) {
        if( e.target.className.indexOf('megamenu__item') != 0 ){
            navheadItem.removeClass('is-selected');
            elBody.removeClass('menu-is-visible');
            elBody.removeClass('with-bg-image');
            $('.megamenu').removeClass('current-visible');
            megamenuBg.find('img').attr('src','');
        }
    });

    // Open touch menù
    $('.toggle-menu').on('click',function() {
        $(this).toggleClass('is-open');
        elBody.toggleClass('menu-mobile-open');
        if( !elBody.hasClass('menu-mobile-open') ){
            $('.nav-mobile').removeClass('submenu-is-open')
            $('.nav-mobile__item').removeClass('is-visible')
        }
    });

    // Scroll event func
    $(window).on('scroll',function() {
        var value = $(window).height() + $(window).scrollTop();
        var scroll  = $(window).scrollTop();
        var $element = $('.site-header');

        if( $('html').hasClass('desktop') || $('html').hasClass('tablet landscape') ){
            if( scroll > 150 ){
                $element.addClass('scroll')
            }else{
                $element.removeClass('scroll')
            }

            if( scroll > 300 ){
                $('body').addClass('menu-sticky-active');
                $element.addClass('sticky-visible');
            }else{
                $('body').removeClass('menu-sticky-active');
                $element.removeClass('sticky-visible');                    
            }
        }
    });

    // Nav mobile menù
    $('.nav-mobile__item.with-child').each(function() {
        var currentItem = $(this).children('a');
        var currentItemList = currentItem.closest('li');

        // Apro il sotto menù
        currentItem.on('click', function(){
            if( currentItemList.hasClass('is-visible') ){
                currentItemList.toggleClass('is-visible');
                $('.nav-mobile').toggleClass('submenu-is-open');
            }else{
                $('.nav-mobile__item').removeClass('is-visible');
                currentItemList.addClass('is-visible');

                if( !$('.nav-mobile').hasClass('submenu-is-open') ){
                    $('.nav-mobile').addClass('submenu-is-open');
                }
            }
        });
    });

    // Mousever item for render subitem
    megamenuItem.on('mouseover', function(e){
        var currentSubitem = $(this).attr('data-item');

        if( $(this).closest('.megamenu').hasClass('current-visible') ){
            findSubitem(currentSubitem);
            controlActive(megamenuItem);
            $(this).addClass('is-active');
        }
    });

    // Mouseover bg megamenu
    // da migliorare
    megamenuSubitem.on('mouseover touchstart', function(){
        var imageBg = $(this).attr('data-bg');

        if( $(this).closest('.megamenu').hasClass('current-visible') ){
            controlActive(megamenuSubitem);
            $(this).addClass('is-active');

            if( imageBg != undefined ){
                elBody.addClass('with-bg-image');
                megamenuBg.find('img').attr('src',imageBg);
                megamenuBg.find('.megamenu__img').fadeIn(200);
            }else{
                elBody.removeClass('with-bg-image');
                megamenuBg.find('.megamenu__img').fadeOut(200);
                megamenuBg.find('img').attr('src','');
            }
        }
    });

    // Find current subitem
    function findSubitem(value) {
        $('.current-visible .megamenu__subitems').each(function () {
            //if( $(this).attr('data-item') == currentSubitem ){
            if( $(this).hasClass('menu-' + value ) ){
                $('.current-visible .megamenu__subitems').removeClass('is-visible');
                $(this).addClass('is-visible');
            }
        });
    }

    // Control subitems active
    function controlActive(element) {
        element.each(function() {
            if( $(this).hasClass('is-active') ){
                $(this).removeClass('is-active');
            }
        });
    }
});