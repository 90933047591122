$(document).ready(function(){

    function filterAgents(zoneId, country) {

        var urlParams = new URLSearchParams(window.location.search);

        urlParams.set('zone', zoneId);

        history.replaceState(null, null, '?' + urlParams.toString());
        window.location.reload();
    }

    if($('body').hasClass('is-agents-view')) {

        $('a.dropdown-item').click(function() {
            // Aggiorna il nome della option selezionata sulla select
            $(this).parent().parent().find('span.dropdown-toggle').html($(this).html());
        });

        //Nazione
        $('.dropdown-countries a.dropdown-item').click(function() {

            // Nasconde tutte le select delle zone (regioni)
            $('.dropdown-zones').hide();

            if($(this).data('has-zones')) {
                // Mostra solo la select delle zone corrispondente alla nazione scelta
                $('.dropdown-zones[data-country="'+$(this).data('country')+'"]').show();
            }else {
                filterAgents($(this).data('id'));
            }
        });

        //Regione
        $('.dropdown-zones a.dropdown-item').click(function() {
            filterAgents($(this).data('id'));
        });
    }
});