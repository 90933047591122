$(document).ready(function(){
    if($('body').hasClass('is-list')) {

        // Reset dei filtri
        $('.reset-filter').click(function() {
            window.history.replaceState(null, null, window.location.pathname);
            window.location.reload();
        });

        // Filtro Select
        $('.filters__item a.dropdown-item').click(function() {
            updateListUrlByFilter(
                $(this).data('field'),
                $(this).data('value')
            );
        });

        $('.filter-toggle').on('click',function() {
            $(this).closest('.nav-foot-sticky').toggleClass('is-open');
        });

        // Input Autocomplete
        $('.nav-foot-sticky .input-autocomplete').each(function () {
            $(this).autocomplete({
                serviceUrl: '/ecommerce/input-autocomplete/' + $(this).data('autocomplete-tenant') + '/' + $(this).data('autocomplete-field'),
                onSelect: function (suggestion) {
                    updateListUrlByFilter(
                        $(this).data('field'),
                        suggestion.value
                    );
                },
                orientation: "top"
            });
        });
        $('.cont-filters .input-autocomplete').each(function () {
            $(this).autocomplete({
                serviceUrl: '/ecommerce/input-autocomplete/' + $(this).data('autocomplete-tenant') + '/' + $(this).data('autocomplete-field'),
                onSelect: function (suggestion) {
                    updateListUrlByFilter(
                        $(this).data('field'),
                        suggestion.value
                    );
                }
            });
        });

        // Filtro Multiselect
        $(".filters__item input.input-multiselect[type=checkbox]").click(function() {

            var urlParams = new URLSearchParams(window.location.search);
            var fieldName = $(this).data('multiselect') + '[]';

            if($(this).is(':checked')) {
                urlParams.append(fieldName, $(this).val());
            }else {
                var params = urlParams.getAll(fieldName);
                urlParams.delete(fieldName);

                for (var i = 0; i < params.length; i++) {
                    if(params[i] !== $(this).val()) {
                        urlParams.append(fieldName, params[i]);
                    }
                }
            }

            history.replaceState(null, null, '?' + urlParams.toString());
            window.location.reload();
        });
    }

    if($('body').hasClass('is-textures')) {
        let downloadableInfoData = getDownloadableObject('textile');
        let localStoreItems = getLocalStoreDownload (downloadableInfoData.localStore);
        markCheckedStoreDownload(downloadableInfoData.localStore,downloadableInfoData.dataID);
        checkItemActive(localStoreItems.length, downloadableInfoData.limitDownload);
    }

    if($('body').hasClass('is-finiture')) {
        let downloadableInfoData = getDownloadableObject('finishing');
        let localStoreItems = getLocalStoreDownload (downloadableInfoData.localStore);
        markCheckedStoreDownload(downloadableInfoData.localStore,downloadableInfoData.dataID);
        checkItemActive(localStoreItems.length, downloadableInfoData.limitDownload);
    }

    // Scroll window
    var firstScrollTop = $(window).scrollTop();
    $(window).on('scroll',function() {
        var scrolling = $(window).scrollTop();
        controlWinPosition(scrolling);
    });

    function controlWinPosition(scroll) {
        if( $('.offset-segnaposto').length != 0 ){
            var offsetSelectDownload = $('.offset-segnaposto').offset().top - 80;

            if( $('.nav-head-sticky').hasClass('is-visible') ){
                if( scroll >= offsetSelectDownload ){
                    $('.nav-head-sticky').addClass('is-fixed');
                    // aggiungo per qualche dispositivo problema con fixed
                    $('.nav-head-sticky').appendTo('body');
                }else{
                    $('.nav-head-sticky').removeClass('is-fixed');
                    // aggiungo per qualche dispositivo problema con fixed
                    $('.nav-head-sticky').insertAfter('.offset-segnaposto');
                }
            }
        }
    }

    // Count active for download and bar appearing
    var checkDownload = $('.grid-list .item-data').find('.btn__confirm');
    checkDownload.on('click',function () {

        let downloadableInfoData = getDownloadableObject($(this).attr('data-downloadable-type'));

        $(this).toggleClass('checked');
        $(this).closest('.item-data').toggleClass('downloadable');

        var localStoreItems = getLocalStoreDownload(downloadableInfoData.localStore);

        if(localStoreItems.length < downloadableInfoData.limitDownload || $(this).hasClass('checked') === false){
            setLocalStoreDownload(downloadableInfoData.localStore, downloadableInfoData.dataID);
            localStoreItems = getLocalStoreDownload(downloadableInfoData.localStore);
            markCheckedStoreDownload(downloadableInfoData.localStore, downloadableInfoData.dataID);
        }else{
            $(this).toggleClass('checked');
        }

        checkItemActive(localStoreItems.length, downloadableInfoData.limitDownload);
        controlWinPosition($(window).scrollTop());
    });

    // Count active for download and bar appearing
    var downloadItems = $('.js-download-button');
    downloadItems.on('click',function () {

        event.preventDefault();

        var clickedElement = $(this);
        clickedElement.prop('disabled',true);
        clickedElement.addClass('clicked');

        let downloadableInfoData = getDownloadableObject($(this).attr('data-downloadable-type'));

        let elementsIds = [];
        elementsIds = getLocalStoreDownload (downloadableInfoData.localStore);

        if(elementsIds.length && elementsIds.length <= downloadableInfoData.limitDownload){
            $.ajax({
                url: downloadableInfoData.url,
                method: "POST",
                data:
                {
                    items: elementsIds,
                },
                success: function (data, status, xhr) {
                    var link = document.createElement('a'),
                        filename = 'meridiani-archive.zip';
                        link.href = URL.createObjectURL(data);
                        link.download = filename;
                        link.click();
                    deleteLocalStoreDownload (downloadableInfoData.localStore);
                    markCheckedStoreDownload(downloadableInfoData.localStore,downloadableInfoData.dataID);
                    let localStoreItems = getLocalStoreDownload (downloadableInfoData.dataID);
                    checkItemActive(localStoreItems.length, downloadableInfoData.limitDownload);
                    clickedElement.prop('disabled',false);
                    clickedElement.removeClass('clicked');
                },
                headers:
                {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                dataType: 'binary',
                xhrFields: {
                    'responseType': 'blob'
                }
            });
        }
    });

    // Check dowloadable
    function checkItemActive(items, downloadLimit) {
        let alertLimittMessage = $('.js-limit-reached');
        alertLimittMessage.hide();

        if(items >= downloadLimit){
            alertLimittMessage.fadeIn('slow');
        }

        if( items >= 1 ){
            $('.nav-head-sticky').addClass('is-visible')
            $('.nav-head-sticky .counter').html(items);
        }else{
            $('.nav-head-sticky').removeClass('is-visible')
        }
    }

    // Aggiornamento URL piazza prodotti sulla base dei filtri selezionati
    function updateListUrlByFilter(fieldName, fieldValue) {

        $('.filters').prop('disabled', true);

        var urlParams = new URLSearchParams(window.location.search);

        if(fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
            urlParams.set(fieldName, fieldValue);
        }else {
            urlParams.delete(fieldName);
        }

        history.replaceState(null, null, '?' + urlParams.toString());
        window.location.reload();
    }


    function getLocalStoreDownload (localStore) {
        var localStoreItems = localStorage.getItem(localStore);
        if(localStoreItems === undefined || localStoreItems === null) {
            localStoreItems = [];
        } else {
            localStoreItems = JSON.parse(localStorage.getItem(localStore));
        }
        return localStoreItems;
    }

    //set store locator
    function setLocalStoreDownload (localStore, dataAttribute){

        //prendo il valore con gli elementi
        let localStoreItems = getLocalStoreDownload (localStore);

        let downloadablesElements = $( ".js-downloadable" );
        let elementsIdsChecked = [];
        let elementsIdsNotChecked = [];

        downloadablesElements.each(function (){
            if($( this ).hasClass('checked')){
                elementsIdsChecked.push($( this ).attr(dataAttribute));
            }else{
                elementsIdsNotChecked.push($( this ).attr(dataAttribute));
            }
        });

        localStoreItems = $.merge(localStoreItems,elementsIdsChecked);
        localStoreItems = getUnique(localStoreItems);
        localStoreItems = arrayDiff(elementsIdsNotChecked, localStoreItems);

        localStorage.setItem(localStore, JSON.stringify(localStoreItems));

    }

    //add checked class store locator
    function markCheckedStoreDownload (localStore, dataAttribute) {
        let localStoreItems = getLocalStoreDownload (localStore);
        $('.js-downloadable').removeClass('checked');
        localStoreItems.forEach(function (element) {
            $("[" + dataAttribute + "=" + element + "]").addClass('checked');
        });
    }

    //delete store locator
    function deleteLocalStoreDownload (localStore) {
        localStorage.removeItem(localStore);
    }

    //Utility
    function arrayDiff(array1,array2) {
        let newItems = [];
        $.grep(array2, function(i) {
            if ($.inArray(i, array1) == -1)
            {
                newItems.push(i);
            }
        });
        return newItems;
    }

    //Utility
    function getUnique(array){
        let uniqueArray = [];

        // Loop through array values
        for(i=0; i < array.length; i++){
            if(uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    function DownloadableElement(localStore, dataID, url, limitDownload) {
      this.localStore = localStore;
      this.dataID = dataID;
      this.url = url;
      this.limitDownload = limitDownload;
    }

    //Utility
    function getDownloadableObject(type) {

        let limitDownload = 10;
        let downloadablesElements = $( ".js-download-button" );
        if(downloadablesElements.attr('data-max-limit-download')){
            limitDownload = downloadablesElements.attr('data-max-limit-download');
        }

        if(type == 'textile'){
            return new DownloadableElement('meridiani_textiles_downloadable', 'data-textile-id', '/download-textiles', limitDownload);
        }

        if(type == 'finishing'){
            return new DownloadableElement('meridiani_finishes_downloadable', 'data-finishing-id', '/download-finishes', limitDownload);
        }

        return null;
    }
});
