$(document).ready(function(){

    if($('body').hasClass('is-locator')) {

        var input = document.getElementById('store_locator_form_search');
        var autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.addListener('place_changed', function () {
            var placeAutocomplete = autocomplete.getPlace();
            if (placeAutocomplete && placeAutocomplete.place_id) {
                $('#store_locator_form_place_id').val(placeAutocomplete.place_id);
                $('form[name="store_locator_form"]').submit();
            }
        });
    }

    if($('body').hasClass('is-locator-view')) {

        //Filtri
        $('.filters__actions button.btn').click(function () {

            var urlParams = new URLSearchParams(window.location.search);
            var storeTypeValue = $('input[name=tipo]:checked').val();
            var radiusValue = parseInt($('.range-value').html());

            Array.from(urlParams.keys()).forEach(key => {
                if(key !== 'id' && key !== 'q') {
                    urlParams.delete(key);
                }
            });

            if(storeTypeValue !== "") {
                urlParams.append('type', storeTypeValue);
            }

            if(radiusValue !== "") {
                urlParams.append('radius', radiusValue);
            }

            history.replaceState(null, null, '?' + urlParams.toString());
            window.location.reload();

        });

        // Reset dei filtri
        $('.reset-filter').click(function() {

            var urlParams = new URLSearchParams(window.location.search);

            Array.from(urlParams.keys()).forEach(key => {
                if(key !== 'id' && key !== 'q') {
                    urlParams.delete(key);
                }
            });

            history.replaceState(null, null, '?' + urlParams.toString());
            window.location.reload();
        });
    }
});

// Range position value
const allRanges = document.querySelectorAll(".filters__rangecont");

allRanges.forEach(wrap => {
  const range = wrap.querySelector(".filters__input--range");
  const bubble = wrap.querySelector(".range-value");

  range.addEventListener("input", () => {
    setBubble(range, bubble);
  });
  setBubble(range, bubble);
});

function setBubble(range, bubble) {
  const val = range.value;
  const min = range.min ? range.min : 0;
  const max = range.max ? range.max : 100;
  const newVal = Number(((val - min) * 100) / (max - min));
  bubble.innerHTML = val + 'km';

  // Sorta magic numbers based on size of the native UI thumb
  bubble.style.left = 'calc('+newVal+'% + ('+ (8 - newVal * 0.15) +'px))';
}